/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../components/custom-header';
import Footer from '../../components/custom-footer';
import Selo from '../../components/custom-selo';
import Navigation from '../../components/custom-navigation/index';
import { scrollToTop } from '../../util/bibli';
import { ContainerPage } from '../../styles/container-page';
import Payment from '../Payment';
import SignIn from '../SignIn';
import ForgotPassword from '../ForgotPassword';
import SignUp from '../SignUp';
import Address from '../Address';
import { useLocation, useParams } from 'react-router-dom';
import Input from '../../components/custom-input';
import { Form } from '@unform/web';
import Loading from '../../components/custom-loading';
import back from '../../assets/images/background/pattern-2.png';
import { toast } from 'react-toastify';
import Terms from '../Terms';
import Checkbox from '../../components/custom-checkbox';
import ModalCe from '../../components/custom-modal';

import { AuthContext } from '../../contexts/auth';
import { ProductContext } from '../../contexts/product';
// eslint-disable-next-line import/named
import { FormHandles } from '@unform/core';
import { trackParams } from '../../network/manager-analytics';

const Checkout: React.FC = () => {
  const { user, dataMe } = useContext(AuthContext);
  const couponRef = useRef(null);
  const termosRef = useRef<FormHandles>(null);
  const address = localStorage.getItem('@Customeasy:AddressUser');
  const address_user = address && JSON.parse(address);
  const location = useLocation();
  const { id } = useParams();
  const [values, setValues] = useState({ voucher: '' });
  const [formActive, setFormActive] = useState('');
  const [dataProducty, setDataProducty] = useState<any>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 993);
  const [check, setCheck] = useState<number>(0);
  const [modal, setModal] = useState({ active: false });
  const [previousRoutes, setPreviousRoutes] = useState<any>(location.state);
  const [modalOffers, setModalOffers] = useState({
    active: false,
    routeButtonRight: '',
  });
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const {
    dataProduct,
    setDataProduct,
    responseProduct,
    response,
    loading,
    getProduct,
    handlePayment,
    setLastSelectedProduct,
    setFormTermosRef,
    handleTermsOfUse,
    setCheckboxEquals,
    isLoadingButtonProd,
    paymentResponse,
  } = useContext(ProductContext);

  async function handleSubmit() {
    handleTermsOfUse();
  }

  function handleChange(newFormActive: React.SetStateAction<string>) {
    setFormActive(newFormActive);
  }

  useEffect(() => {
    scrollToTop();
    trackParams('screen_view', 'checkout_screen', [
      {
        propertie: 'route',
        value: location.pathname,
      },
    ]);
    setFormTermosRef(termosRef);
    getProduct(id);
    if (user !== undefined) {
      if (address_user) {
        setCheck(2);
        setDataProducty([arrayCard[2]]);
      } else {
        setCheck(1);
        setDataProducty([arrayCard[1]]);
      }
    }
    if (
      response?.resposta?.category === 'CustomToYou' &&
      dataMe?.offers?.custom_pro &&
      previousRoutes?.previousRoute !== '/'
    ) {
      setModalOffers({ active: true, routeButtonRight: dataMe.offers?.route });
    }
  }, [user, dataMe]);

  function handleCouponInput(value: string) {
    setValues((prev: any) => ({ ...prev, voucher: value }));
  }

  async function handleSubmitCoupon() {
    try {
      setIsLoadingButton(true);
      await getProduct(id, values.voucher);
      setIsLoadingButton(false);
    } catch (err: any) {
      setIsLoadingButton(false);

      toast.error(`Ocorreu um erro ao aplicar o cupom! ${err}`, {
        position: 'top-center',
        theme: 'colored',
        style: {
          marginTop: screen.height * 0.4,
        },
      });
    }
  }

  const arrayCard = [
    {
      title: 'Login',
      component: (
        <SignIn
          onClick={handleChange}
          colorLabel="#404040"
          colorLabelFooter="#404040"
        />
      ),
    },
    { title: 'Endereço', component: <Address /> },
    {
      title: 'Pagamento',
      component: (
        <Payment
          buttonTitle={
            response?.resposta?.actionButtons[0].title || 'Comprar agora'
          }
          isLoading={isLoadingButtonProd}
          isMobile={isMobile}
          modalFunction={() => {
            setModal({ active: true });
            return null;
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Loading visibled={loading} />
      <ModalCe
        title="Já imaginou poder fazer demonstração para seus clientes sem custo?"
        subTitle="Com um plano CustomPro, além de poder fazer demonstrações para seus clientes sem custo, você ainda economiza no valor por carro, dentre outras vantagens."
        titleButtonLeft="Continuar sem modo demonstração "
        titleButtonRigth="Mudar para CustomPro 5"
        size="lg"
        onClick={() => {
          window.location.href = `/checkout/${modalOffers.routeButtonRight}/details`;
        }}
        controllerModal={[modalOffers.active, setModalOffers]}
      />
      <ModalCe
        size="lg"
        title="Termos de uso CustomEasy"
        subTitle={<Terms />}
        titleButtonLeft="Concordo"
        controllerModal={[modal.active, setModal]}
      />
      <Header />
      <Row className="m-0 header-page">
        <Col
          lg={12}
          className="page-title"
          id={`${
            response?.resposta.carsQuantity === 1 ? 'to-you' : 'custom-pro'
          }`}
        >
          <div className="auto-container">
            <h2>{response?.resposta.pageTitle}</h2>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>CheckOut</li>
            </ul>
          </div>
        </Col>
      </Row>
      <ContainerPage style={{ backgroundImage: `url(${back})` }}>
        <div className="auto-container">
          <Row className="justify-content-center" id="box_cards">
            <Col lg={5} md={12} sm={12} id="pagamento-plano">
              <div
                className={`widget-heading return-widget position-absolute float-left ${
                  formActive === '' ? 'd-none' : 'd-block'
                } p-2`}
                style={{ zIndex: '9999' }}
              >
                <a
                  onClick={() => {
                    setFormActive('');
                  }}
                  className="return-side-widget"
                  style={{ color: '#4b90cf', fontSize: '18px' }}
                >
                  <span className="fa fa-arrow-left" id="arrow-back" />
                </a>
              </div>
              <div
                className="shop-order-box"
                style={{ backgroundColor: '#F2F2F2' }}
              >
                <Navigation
                  callback={(data: any, index: number) => {
                    setCheck(index);
                    setDataProducty([data]);
                  }}
                  responseProduct={arrayCard}
                  qtdeOptions={arrayCard.length}
                  firstSelected={check}
                  type="pagamento"
                  noMessage
                />
                <div className="row m-0 justify-content-center">
                  {dataProducty?.title === 'Login' ||
                  dataProducty.length === 0 ? (
                    <div className="col-10">
                      {formActive === '' ? (
                        <SignIn
                          onClick={handleChange}
                          colorLabel="#404040"
                          colorLabelFooter="#404040"
                        />
                      ) : formActive === 'forgot-password' ? (
                        <ForgotPassword colorLabel="#404040" />
                      ) : (
                        <SignUp colorLabel="#404040" />
                      )}
                    </div>
                  ) : (
                    <div className="">{dataProducty[0].component}</div>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={5} md={12} sm={12} id="assinatura-plano">
              <div
                className="shop-order-box"
                style={{ backgroundColor: '#F2F2F2' }}
              >
                {response && dataProduct ? (
                  <>
                    <div
                      style={{
                        marginBottom: responseProduct?.length === 1 ? 10 : 0,
                      }}
                    >
                      <h5 className="text-center">
                        {response?.resposta.checkoutTitle}
                      </h5>
                    </div>
                    {loading ? (
                      <Loading loading_card />
                    ) : (
                      <>
                        {responseProduct?.length > 1 && (
                          <Navigation
                            callback={(data: any, index: any) => {
                              setLastSelectedProduct(index);
                              setDataProduct([data]);
                            }}
                            responseProduct={responseProduct}
                            qtdeOptions={responseProduct?.length}
                            firstSelected={response?.firstSelect}
                            type="meses"
                          />
                        )}
                        {dataProduct?.map((dados: any, idx: number) => {
                          return (
                            <div key={`id_${idx}`}>
                              <ul className="order-list">
                                <li>
                                  <span className="valor-mes">
                                    {dados.checkoutDetails.monthMessageText}
                                  </span>
                                </li>
                                <li className="mt-2">
                                  <span className="valor-periodo">
                                    {
                                      dados.checkoutDetails
                                        .recurrenceMessageText
                                    }
                                  </span>
                                </li>
                              </ul>
                              <div className="shop-form">
                                <Form
                                  ref={couponRef}
                                  onSubmit={handleSubmitCoupon}
                                >
                                  <div className="row align-items-center m-0">
                                    <div
                                      className=" ps-0 desconto "
                                      style={{ flex: 10 }}
                                    >
                                      <Input
                                        className="flex-10 input"
                                        name="coupon"
                                        type="text"
                                        placeholder="Cupom de desconto"
                                        onChange={(e) => {
                                          handleCouponInput(e.target.value);
                                        }}
                                        value={values.voucher}
                                        required
                                      />
                                    </div>
                                    <div className="p-0" style={{ flex: 1 }}>
                                      <button
                                        type="submit"
                                        className="theme-btn btn-style-one ps-2 pe-2"
                                        disabled={isLoadingButton}
                                      >
                                        <span className="txt">
                                          {isLoadingButton ? (
                                            <div
                                              style={{
                                                height: 30,
                                                width: '58.15px',
                                              }}
                                            >
                                              <Loading loading_button />
                                            </div>
                                          ) : (
                                            'Aplicar'
                                          )}
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                              <ul className="order-list border-0 pe-0">
                                <li>
                                  <span>
                                    {
                                      dados.checkoutDetails.valuesMessageText
                                        .resume
                                    }
                                  </span>
                                </li>
                                {dados.checkoutDetails.valuesMessageText
                                  .discount ? (
                                  <li>
                                    <span
                                      style={{
                                        color: '#404040',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      -
                                    </span>
                                    <span className="ps-2">
                                      {
                                        dados.checkoutDetails.valuesMessageText
                                          .discount
                                      }
                                    </span>
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </ul>

                              <ul className="order-list border-0 p-0">
                                <li className="total mb-0 pt-3">
                                  <span>
                                    {
                                      dados.checkoutDetails.finalMessageText
                                        .title
                                    }
                                  </span>
                                </li>
                                <li>
                                  <p>
                                    {
                                      dados.checkoutDetails.finalMessageText
                                        .subtitle
                                    }
                                  </p>
                                </li>
                              </ul>
                              <div className="row">
                                <div className="col-sm-12 justify-content-center align-items-center d-flex">
                                  {!paymentResponse?.recurrenceData?.Payment &&
                                    response.resposta.actionButtons.map(
                                      (item: any, index: number) => {
                                        return (
                                          <button
                                            disabled={isLoadingButtonProd}
                                            style={{ marginBottom: '1rem' }}
                                            key={`id_${index}`}
                                            type="submit"
                                            className="theme-btn btn-style-one col-12"
                                            onClick={() => {
                                              if (user && address_user) {
                                                handlePayment();
                                                handleTermsOfUse();
                                              } else {
                                                window.scrollBy({
                                                  top:
                                                    window.innerHeight / 2 - 50,
                                                });

                                                toast.error(
                                                  `${
                                                    !address_user && user
                                                      ? 'Preencha o endereço para continuar.'
                                                      : 'Faça login para continuar.'
                                                  }`,
                                                  {
                                                    autoClose: 6000,
                                                    position: 'top-center',
                                                    theme: 'colored',
                                                    style: {
                                                      marginTop: `${
                                                        location.pathname ===
                                                        '/sign-up/login'
                                                          ? '0'
                                                          : '200px'
                                                      }`,
                                                    },
                                                  }
                                                );
                                              }
                                            }}
                                          >
                                            <span className="txt">
                                              {isLoadingButtonProd ? (
                                                <div
                                                  style={{
                                                    height: 30,
                                                  }}
                                                >
                                                  <Loading loading_button />
                                                </div>
                                              ) : (
                                                item.title
                                              )}
                                            </span>
                                          </button>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {!paymentResponse?.recurrenceData?.Payment && (
                      <Form ref={termosRef} onSubmit={handleSubmit}>
                        <Checkbox
                          noMargin
                          name="termos_uso"
                          onChange={(e: any) => setCheckboxEquals(e)}
                          label={
                            <>
                              Concordo com os{' '}
                              <a
                                href=""
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setModal({ active: true });
                                }}
                              >
                                Termos de uso
                              </a>
                            </>
                          }
                        />
                      </Form>
                    )}
                  </>
                ) : (
                  <Container>
                    <Row>
                      <Col lg={12} md={12} sm={12} className="pt-4">
                        <h3 className="text-center pt-4">Erro</h3>
                        <hr className="hr-plano bg-dark border-1 border-top border-dark" />
                        <p className="text-center m-0">
                          Não foi possível obter as informações de planos! Tente
                          novamente ou entre em contato com nosso suporte.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </ContainerPage>
      <Selo />
      <Footer />
    </>
  );
};
export default Checkout;
