/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
  useContext,
  useEffect,
  useRef,
} from 'react';
import api from '../services/api';
import { AuthContext } from '../contexts/auth';
import { isCreditCardDate, isCreditCardNumber } from '../util/validationsCard';
import * as Yup from 'yup';
import { errorYup, translate } from '../util/bibli';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackParams } from '../network/manager-analytics';

import ReCAPTCHA from 'react-google-recaptcha';

interface ProductContextData {
  dataProduct: any;
  setDataProduct: any;
  responseProduct: any;
  response: any;
  setResponse: any;
  getProduct: Function;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  lastSelectedProduct: any;
  setLastSelectedProduct: Dispatch<SetStateAction<any>>;
  coupon: string;
  paymentData: any;
  setPaymentData: any;
  handlePayment: Function;
  formRef: any;
  paymentResponse: any;
  setFormRef: Function;
  handleTermsOfUse: Function;
  setCheckboxEquals: Function;
  formTermosRef: any;
  setFormTermosRef: Function;
  disabledButton: boolean;
  setDisabledButton: Dispatch<SetStateAction<boolean>>;
  isLoadingButtonProd: boolean;
  setIsLoadingButtonProd: Dispatch<SetStateAction<boolean>>;
  formSelected: string;
  setFormSelected: Dispatch<SetStateAction<string>>;
}
interface AuthContextData {
  children?: ReactNode;
}

export const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData
);

let pollingInterval: NodeJS.Timer;

const ProductProvider: React.FC<AuthContextData> = (props: AuthContextData) => {
  const { children } = props;
  const { authenticated, getMe } = useContext(AuthContext);
  const navigate = useNavigate();
  const link = localStorage.getItem('@Customeasy:LinkPartner');

  const [formSelected, setFormSelected] = useState('');

  const [dataProduct, setDataProduct] = React.useState<any>([]);
  const [responseProduct, setResponseProduct] = React.useState<any>(null);
  const [response, setResponse] = React.useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [coupon, setCoupon] = React.useState<any>([]);
  const [formTermosRef, setFormTermosRef] = React.useState<any>({});
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [isLoadingButtonProd, setIsLoadingButtonProd] = useState(false);
  const [lastSelectedProduct, setLastSelectedProduct] = useState(-1);
  const [paymentData, setPaymentData] = useState<any>({});
  const [formRef, setFormRef] = useState<any>({});
  const [paymentResponse, setPaymentResponse] = useState<any>({});

  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const getProduct = async (id: number, voucher: string) => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
    console.log(location.pathname);
    try {
      if (!voucher) setLoading(true);

      const response = await api.get(`/products/${id}/details`, {
        params: { coupon: voucher ? voucher : null },
      });

      if (voucher && response) {
        toast.success('Cupom adicionado com sucesso!', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
          autoClose: 1500,
        });
      }

      setCoupon(voucher);
      setResponseProduct(response.data.products);

      const produtos =
        voucher && lastSelectedProduct > -1
          ? [response.data.products[lastSelectedProduct]]
          : response.data.products.filter(
              (data: any) => data.selected === true
            );
      setDataProduct(produtos);
      const firstSelect = response.data.products.findIndex(
        (i: { selected: boolean }) => i.selected === true
      );

      setResponse({
        resposta: response.data,
        firstSelect:
          lastSelectedProduct > -1 ? lastSelectedProduct : firstSelect,
      });

      setLoading(false);
    } catch (err: any) {
      console.log('err ', err);
      if (err.response.data) {
        toast.error(translate(err.response.data), {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      } else {
        toast.error('Ocorreu um erro!  ' + err.response.data.error.message, {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }
    }
  };

  const successAction = () => {
    navigate('/account');

    toast.success('Pagamento recebido com sucesso', {
      position: 'top-center',
      theme: 'colored',
      style: {
        marginTop: screen.height * 0.4,
      },
    });

    setPaymentResponse(null);
  };

  const handlePayment = async () => {
    try {
      let schema;

      schema = Yup.object().shape({
        termos_uso: Yup.string().matches(/S/g, 'Campo Obrigatório').trim(),
      });

      if (formSelected === 'credit-card') {
        schema = Yup.object().shape({
          creditCardNumber: Yup.string()
            .test({
              message: 'Cartão de crédito inválido',
              test: (value) => isCreditCardNumber(value),
            })
            .required('Numero do cartão de crédito é obrigatório')
            .trim(),
          creditCardName: Yup.string()
            .required('Nome do titular do cartão é obrigatório')
            .trim(),
          expireData: Yup.string()
            .required('Data é obrigatória')
            .test({
              message: 'Data informada é inválida',
              test: (value) => isCreditCardDate(value),
            })
            .trim(),
          creditCardCvc: Yup.string().required('CVC é obrigatório').trim(),
          termos_uso: Yup.string().matches(/S/g, 'Campo Obrigatório').trim(),
        });
      }

      await schema.validate(formRef.current?.getData(), {
        abortEarly: false,
      });

      if (formTermosRef.current?.getData().termos_uso === 'S') {
        // setLoading(true);
        setIsLoadingButtonProd(true);
        setDisabledButton(true);

        let paymentData;

        if (formSelected === 'credit-card') {
          const number = formRef.current
            ?.getData()
            .creditCardNumber.replace(/\s+/g, '');
          paymentData = {
            credit_card: {
              holder_name: formRef.current?.getData().creditCardName,
              card_number: number,
              expiration_date: formRef.current?.getData().expireData,
              security_code: formRef.current?.getData().creditCardCvc,
            },
          };
        }

        let uri = '/orders/payment';

        let recaptcha = null;

        if (formSelected !== 'credit-card') {
          uri =
            formSelected === 'bank-slip'
              ? '/orders/payment-bank-slip'
              : '/orders/payment-bank-transfer';
        }

        if (formSelected === 'credit-card') {
          recaptcha = await recaptchaRef?.current?.executeAsync();
        }

        const data = {
          product_id: dataProduct[0]?.id,
          coupon: coupon ? coupon : link ? link : undefined,
          type: formSelected,
          payment_data: paymentData || undefined,
        };

        const headers: { Authorization: string; reToken?: string } = {
          Authorization: `Bearer ${authenticated?.authUserToken}`,
        };

        if (recaptcha) {
          headers.reToken = recaptcha;
        }

        const responsePayment = await api.post(uri, data, {
          headers: headers,
        });

        if (responsePayment.status === 200) {
          const dataTrack = [
            {
              propertie: 'itemToPurchase',
              value: response.resposta.pageTitle,
            },
            {
              propertie: 'idItemToPurchase',
              value: dataProduct[0]?.id,
            },
            {
              propertie: 'statusPurchase',
              value: 'success',
            },
            {
              propertie: 'valuePurchase',
              value: dataProduct[0].checkoutDetails.finalMessageText.title,
            },
            {
              propertie: 'routeName',
              value: location.pathname,
            },
          ];

          trackParams('checkout_web_event', 'checkout_screen', dataTrack);

          setIsLoadingButtonProd(false);

          setPaymentResponse(responsePayment.data);
          console.log('responsePayment.data', responsePayment.data);

          if (formSelected === 'bank-transfer') {
            let count = 0;

            pollingInterval = setInterval(async () => {
              const order = responsePayment.data.orderData;

              if (count === 300) {
                clearInterval(pollingInterval);
                return;
              }

              try {
                const orderUpdated = await api.get(`/orders/${order.id}`, {
                  headers: {
                    Authorization: `Bearer ${authenticated?.authUserToken}`,
                  },
                });

                if (orderUpdated.data.data.attributes.status === 'completed') {
                  clearInterval(pollingInterval);

                  successAction();

                  return;
                }

                console.log('orderUpdated', orderUpdated);
              } catch (err) {
                console.log('err');
              }

              count += 1;
            }, 1000);

            return;
          }

          console.log('responsePayment');

          successAction();

          return;

          // setLoading(false);
        }
      } else {
        setLoading(false);
        trackParams('err_not_accept_terms', location.pathname);
        toast.error('É necessário concordar com os termos de uso!', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }
    } catch (err: any) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formRef);
        if (formTermosRef.current?.getData().termos_uso === 'N') {
          trackParams('err_not_accept_terms', location.pathname);
          toast.error('É necessário concordar com os termos de uso!', {
            position: 'top-center',
            theme: 'colored',
            style: {
              marginTop: screen.height * 0.4,
            },
          });
        }
        return;
      } else {
        setIsLoadingButtonProd(false);
        setDisabledButton(false);
        setLoading(false);

        toast.error('Falha ao processar o pagamento!. Tente Novamente!', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }
    }
  };

  function setCheckboxEquals(ev: any) {
    const { name, checked } = ev.currentTarget;
    if (name === 'termos_uso') {
      formTermosRef.current?.setFieldValue('termos_uso', checked ? 'S' : 'N');
      formRef.current?.setFieldValue('termos_uso', checked ? 'S' : 'N');
    }
  }

  const handleTermsOfUse = async () => {
    try {
      const schema = Yup.object().shape({
        termos_uso: Yup.string().matches(/S/g, 'Campo Obrigatório').trim(),
      });
      await schema.validate(formTermosRef.current?.getData(), {
        abortEarly: false,
      });
    } catch (err: any) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        errorYup(err, formTermosRef);
        return;
      } else {
        toast.error('Erro', {
          position: 'top-center',
          theme: 'colored',
          style: {
            marginTop: screen.height * 0.4,
          },
        });
      }
    }
  };

  useEffect(() => {
    setPaymentResponse(null);
  }, [dataProduct]);

  return (
    <ProductContext.Provider
      value={{
        coupon,
        dataProduct,
        setDataProduct,
        responseProduct,
        response,
        setResponse,
        loading,
        setLoading,
        getProduct,
        paymentData,
        setPaymentData,
        handlePayment,
        paymentResponse,
        formRef,
        setFormRef,
        lastSelectedProduct,
        setLastSelectedProduct,
        handleTermsOfUse,
        setCheckboxEquals,
        formTermosRef,
        setFormTermosRef,
        disabledButton,
        setDisabledButton,
        isLoadingButtonProd,
        setIsLoadingButtonProd,
        formSelected,
        setFormSelected,
      }}
    >
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdZz_UqAAAAAF9gH_h_rEnh5W6l8TcHhzxRpTXl"
      />
    </ProductContext.Provider>
  );
};

export { ProductProvider };
